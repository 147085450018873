
export default {
  computed: {
    siteId() {
      return this.$store.state.site_id;
    }
  },
  methods: {
    logoClick() {
      this.$router.push("/");
      setTimeout(() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      );
    }
  }
};
